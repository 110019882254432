import { useState, useEffect, useRef } from "react";
import backgroundimg from "../Images/banner-bg.jpg";
import Calenderimg from "../Images/provider/IMG-20240905-WA0027.jpg";
import emailjs from "emailjs-com";
import { countriesList } from "../utility/constants";
import { styled, useMediaQuery } from "@material-ui/core";
import { Helmet } from "react-helmet";
import CenteredAlert from "./CenteredAlert";
import CircularProgress from "@material-ui/core/CircularProgress";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import whatsapp from "../Images/whatsapp.png";
// import { revolutionizes } from "./config";
import { revolutionizes } from "./config";
import NukaCarousel from "../tools/NukaCarousel";
import { faqs } from "./config";

import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  FormHelperText,
  Select,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

  button: {
    // margin: theme.spacing(2, 0),
    backgroundColor: "#008b91",
    marginTop: "2rem",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#02585c",
    },
  },

}));

const Patientregis = () => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [countryname, setCountryname] = useState("India");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("India");
  const [city, setCity] = useState("");
  const isMobile = useMediaQuery("(max-width:710px)");
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false); // State for managing loading state
  const [expandedQuestions, setExpandedQuestions] = useState([]);

  const isValidPhoneNumber = (phoneNumber) => {
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
    return numericPhoneNumber.length === 10 && /^\d+$/.test(numericPhoneNumber);
  };
  const showAlerts = () => {
    setShowAlert(true);
    resetForm();
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setMessage("");
    setAge("");
    setGender("");
    setPhone("");
    setCountry("");
    setCity("");
  };

  const handlesubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const serviceid = "service_7yxtqsn";
    const templateid = "template_x3wusd7";
    const publickey = "4UpyJJj3P5JGeMZFz";
    const messageContent = `
    Patient Name: ${name}
    Age: ${age}
    Gender: ${gender}
    Phone Number: ${phoneCode}${phone}
    Email: ${email}
    Country: ${country}
    City: ${city}
    Chief Complaints: ${message}
  `;

    const templateparams = {
      from_name: name,
      from_email: email,
      to_name: "web",
      message: messageContent,
    };

    emailjs
      .send(serviceid, templateid, templateparams, publickey)
      .then((response) => {
        setLoading(false);
        console.log("email set successfully", response);
        showAlerts();
      })
      .catch((error) => {
        setLoading(false);
        console.log("error sending email", error);
      });
  };

  const toggleQuestion = (id) => {
    if (expandedQuestions.includes(id)) {
      setExpandedQuestions(expandedQuestions.filter((qid) => qid !== id));
    } else {
      setExpandedQuestions([...expandedQuestions, id]);
    }
  };

  const handleclick = () => {
    window.open("https://calendly.com/rxonecare");
  };

  const titlle =
    "FREE Treatment Advice - Consult for Medical Treatment Advice - Allopathy, Homeopathy, Ayurveda, Unani, Sowa-Rigpa, Siddha, Yoga, Reki, Naturopathy, Meditation, Physiotherapy, Mental Health | RxOne";
  const desc =
    "Get free treatment advice and consult for medical treatments including Allopathy, Homeopathy, Ayurveda, Unani, Sowa-Rigpa, Siddha, Yoga, Reki, Naturopathy, Meditation, Physiotherapy, and Mental Health at RxOne.";
  return (
    <>
      <Helmet>{<title>{titlle}</title>}</Helmet>
      <Helmet>{<meta name="description" content={desc} />}</Helmet>

      <div className="patient">
        <span
          style={{
            width: "100%",
            height: "300px",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
            background: "#018C92",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
        <div
          style={{
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: isMobile ? "column" : "row", // Change flex direction based on screen size
            marginTop: isMobile ? "-77px" : "0",
          }}
        >
          <div
            style={{
              maxWidth: isMobile ? "100%" : "45%", // Set maxWidth to 100% for mobile
              // textAlign: "center", // Center align text on mobile
              marginTop: isMobile ? "0" : "355px", // Remove negative margin on mobile
            }}
          >
            <h1
              className="ms-font-weight-600 mb-4 "
              style={{
                fontSize: "44px",
                marginTop: isMobile ? "69px" : "-430px",
                color: "#fff"
              }}
            >
              Talk to Medical Expert for
              <span style={{ color: "#fff" }}> FREE</span>
            </h1>
            <ul className="mb-2">
              <li
                className="ms-font-weight-600 mb-4 ms-primary-color"
                style={{ color: "gray", fontWeight: "bold", fontSize: "21px" }}
              >
                Find answers to your medical questions
              </li>
              <li
                className="ms-font-weight-600 mb-4 ms-primary-color"
                style={{ color: "gray", fontWeight: "bold", fontSize: "21px" }}
              >
                Find the best doctor and hospital
              </li>
              <li
                className="ms-font-weight-600 mb-4 ms-primary-color"
                style={{ color: "gray", fontWeight: "bold", fontSize: "21px" }}
              >
                Find the right cost of your treatment
              </li>
            </ul>

            <p style={{ fontWeight: "bold", color: "gray", fontSize: "21px" }}>
              And get assistance for everything in between
            </p>
          </div>
          <div className="whatsapp-block">
            <a
              href="https://api.whatsapp.com/send?phone=+917703934446&text=Hello%20RxOne,%20Could%20you%20please%20call%20back."
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={whatsapp} alt="whtsp" />
            </a>{" "}
          </div>
          <div style={{ float: "inline-end" }}>
            <Container component="main" maxWidth="xs">
              <div
                className="provider__revolutionizes__item"
                // style={{
                //   backgroundColor: "#fff",
                //   width: "100%",
                //   borderRadius: "55px",
                //   boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 16px",
                // }}
              >
                <img
                  src={Calenderimg}
                  alt=""
                  style={{ height: "26rem", width: "27rem", borderRadius: "31px" }}
                />

<Button
  className={classes.button}
  variant="contained"
  color="primary"
  type="submit"
  disabled={loading}
  onClick={handleclick}
  style={{ width: "109%" }}
>
  {loading ? (
    <CircularProgress size={24} color="inherit" />
  ) : (
    <span style={{fontSize: "1.3rem", fontWeight: "900"}}>Book Now</span>
  )}
</Button>

                {/* <h4
                  className="ms-font-weight-600 mb-4 ms-primary-color"
                  style={{ textAlign: "center" }}
                >
                  Get answers to your medical questions
                </h4> */}
                {/* <form style={{ textAlign: "center" }} onSubmit={handlesubmit}>
                  <TextField
                    id="firstName"
                    name="first_name"
                    label="Patient  full Name"
                    variant="filled"
                    style={{ marginBottom: "20px", backgroundColor: "white" }} 
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                    fullWidth
                    className={classes.nameField}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff", 
                      },
                      inputProps: {
                        style: { marginTop: "7px", marginBottom: "-3px" },
                      },
                    }}
                  />
                   <TextField
                    id="age"
                    name="age"
                    label="Patient Age"
                    variant="filled"
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                    style={{ marginBottom: "20px", backgroundColor: "white" }}
                    fullWidth
                    className={classes.nameField}
                    onKeyPress={(e) => {
                      
                      const allowedChars = /^[0-9]*$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    inputProps={{
                      maxLength: 3, 
                    }}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff",marginTop: "7px", marginBottom: "-3px" 
                    }}}
                  /> 
                   <FormControl
                    variant="filled"
                    style={{ marginBottom: "20px" }}
                    fullWidth
                    className={classes.nameField}
                    classes={{ root: classes.root }} 
                  >
                    <InputLabel id="gender">Gender</InputLabel>
                    <Select
                      labelId="gender"
                      id="gender"
                      name="gender"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      classes={{ root: classes.root }} 
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>

                 
                  <FormControl
                    variant="filled"
                    fullWidth
                    className={classes.nameField}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="w-23"
                        value={phoneCode} 
                        classes={{ root: classes.root }}
                        style={{ marginTop: "16px" }}
                      >
                        {countriesList &&
                          countriesList.map((item, index) => (
                            <MenuItem
                              value={item.code}
                              key={item.code + "-" + index}
                              classes={{ root: classes.menuItem }}
                            >
                              {item.code}
                            </MenuItem>
                          ))}
                      </Select>

                      <InputLabel
                        htmlFor="phoneNumber"
                        style={{ marginLeft: "101px" }}
                      >
                        Phone Number*
                      </InputLabel>
                      <Input
                        id="phoneNumber"
                        name="phone"
                        label="Phone Number"
                        required
                        value={phone}
                        onChange={(e) => {
                          const value = e.target.value;
                          const allowedChars = /^[0-9]*$/; 

                          if (allowedChars.test(value)) {
                            setPhone(value.slice(0, 10)); 
                            setPhoneError(false); 
                          } else {
                            setPhoneError(true);
                          }
                        }}
                        error={phoneError}
                        style={{ flex: 1, marginLeft: 6, width: "100px" }}
                        className={classes.nameField}
                        InputProps={{
                          style: {
                            backgroundColor: "#fff",
                          },
                        }}
                      />
                    </div>
                    {phoneError && (
                      <FormHelperText error>
                        Please enter a valid phone number.
                      </FormHelperText>
                    )}
                  </FormControl>
                  <TextField
                    multiline
                    maxRows={4}
                    id="chiefComplaints"
                    name="message"
                    label="Your Questions"
                    variant="filled"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    fullWidth
                    style={{ marginBottom: "20px", backgroundColor: "white" }} 
                    className={classes.nameField}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff", 
                      },
                      inputProps: {
                        style: { marginTop: "7px", marginBottom: "-3px" }, 
                      },
                    }}
                  />
                   <TextField
                    id="email"
                    name="email"
                    label="Email"
                    variant="filled"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    required
                    style={{ marginBottom: "20px", backgroundColor: "white" }}
                    className={classes.nameField}
                    inputProps={{
                      pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                      title: "Please enter a valid email address",
                    }}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff", 
                      },
                      inputProps: {
                        style: { marginTop: "7px", marginBottom: "-3px" }, 
                      },
                    }}
                  /> 
                   <FormControl
                    id="country"
                    name="country"
                    variant="filled"
                    required
                    fullWidth
                    style={{ marginBottom: "20px", backgroundColor: "white" }}
                    className={classes.nameField}
                  >
                    <InputLabel id="gender">Select your country</InputLabel>
                    <Select
                      labelId="country"
                      id="country"
                      className="w-23"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      classes={{ root: classes.root }}
                      InputProps={{
                        style: { backgroundColor: "#fff" },
                        inputProps: { style: { maxWidth: "100px" } },
                      }}
                      MenuProps={{
                        components: {
                          MenuItem: (props) => (
                            <MenuItem
                              {...props}
                              classes={{ root: classes.menuItem }}
                              style={{ maxWidth: "100px" }}
                            >
                              {props.children}
                            </MenuItem>
                          ),
                        },
                      }}
                    >
                      {countriesList &&
                        countriesList.map((item, index) => (
                          <MenuItem
                            value={item.name}
                            key={`${item.name}-${index}`}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl> 
                 <TextField
                    id="city"
                    name="city"
                    label="Enter your city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    variant="filled"
                    required
                    fullWidth
                    style={{ marginBottom: "20px", backgroundColor: "white" }} 
                    className={classes.nameField}
                    InputProps={{
                      style: {
                        backgroundColor: "#fff", 
                      },
                      inputProps: {
                        style: { marginTop: "7px", marginBottom: "-3px" }, 
                      },
                    }}
                  /> 
                  <span
                    style={{
                      fontSize: "11px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    *We value your privacy, your details will be kept
                    confidential. Refer our{" "}
                    <a href="https://rxone.app/privacypolicy">Privacy Policy</a>
                  </span>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={loading} 
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </form> */}
              </div>
            </Container>
          </div>
        </div>
      </div>
      {showAlert && (
        <CenteredAlert
          message="We have received your request, please allow us up-to 24 Hrs. to come back to you."
          onClose={() => setShowAlert(false)}
        />
      )}
      <section
        style={{
          background: "linear-gradient(120deg, rgb(237, 233, 233), #edf2f7)",
        }}
        className="ms-apart-section"
      >
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-12 text-center">
              <h2
                // data-aos="fade-up"
                className="ms-font-weight-600 ms-primary-color"
              >
                How it works?
              </h2>
            </div>
          </div>
          <div className="row g-4 provider__revolutionize_container align-items-center">
            {!!revolutionizes?.length ? (
              <NukaCarousel
                nextDisabled={true}
                previousDisabled={true}
                renderCenterLeftControls={false}
                renderCenterRightControls={false}
                carouselClass="provider__revolutionize__carousel"
                slidesToShow={3}
                slidesToshowOnMobile={1}
                props={{
                  cellSpacing: 10,
                  autoplay: true,
                  autoplayInterval: 1000,
                  wrapAround: true,
                }}
              >
                {revolutionizes?.map((item, index) => (
                  <div className="provider__revolutionize__item" key={index}>
                    <div className="provider__revolutionize__icon">
                      <img src={item?.icon} alt={item?.alt} />
                    </div>
                    <h4 className="provider__revolutionize__title text-center ms-font-weight-600 mt-3 mb-4 ms-primary-color">
                      {item?.title}
                    </h4>
                  </div>
                ))}
              </NukaCarousel>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>

      <h5 className="mb-1" style={{ marginLeft: "1rem", fontSize: "1.4rem" }}>
        Frequently Asked Questions
      </h5>

      <ul style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
        {faqs.map((faq) => (
          <li className="languages card" key={faq.id}>
            <div>
              <strong>{faq.question}</strong>
              <button
                onClick={() => toggleQuestion(faq.id)}
                style={{
                  border: "none",
                  background: "none",
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  marginTop: "-22px",
                }}
              >
                <span>{expandedQuestions.includes(faq.id) ? "▲" : "▼"}</span>
              </button>
            </div>
            {expandedQuestions.includes(faq.id) && <p>{faq.answer}</p>}
          </li>
        ))}
      </ul>
    </>
  );
};
export default Patientregis;
