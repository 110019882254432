import React, { useEffect, useState, useContext, useRef } from "react";
import "../Css/booking.css";
import doctorTemplate from "../Images/doctor-template.png";
import "aos/dist/aos.css";
import AOS from "aos";
import AddLocationOutlinedIcon from "@mui/icons-material/AddLocationOutlined";
import ServiceCall from "../ServiceCall";
import AppointmentScreen from "./AppointmentScreen";
import { Modal, Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Doctorprofile from "./Doctorprofile";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import GradingIcon from "@mui/icons-material/Grading";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import { useMediaQuery } from "@material-ui/core";

function Booking() {
  const [show, setShow] = useState(false);
  const mode = process.env.REACT_APP_MODE;
  const book_Appointment = process.env.REACT_APP_BOOK_WIDGET;
  const patientportal = process.env.REACT_APP_PATIENTPORTAL;
  const [details, setDetails] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [doctorID, setDoctorID] = useState("");
  const [hospitalID, setHospitalID] = useState("");
  const [doctorDetails, setDoctorDetails] = useState("");
  const [showAppointment, setShowAppointment] = useState(false);
  const [showPerson, setPerson] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [limit] = useState(10); // Number of doctors per page
  const [hasMore, setHasMore] = useState(true); // Whether there are more doctors to load

  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  const navigate = useNavigate();
  const { code } = useParams();

  const location = useLocation(); // Access location state

  const [callBackFun, setCallBackFun] = useState("");
  const [lookup, setLookup] = useState([]); // State to store fetched records
  const [selectedFilter, setSelectedFilter] = useState('MODE');  // const [search, setSearch] = useState("");

  // useEffect(() => {
  //   if (callBackFun == "data") {
  //     data();
  //     AOS.init({ duration: 500 });
  //     setCallBackFun("");
  //   }
  // }, [callBackFun]);

  // useEffect(() => {
  //   const data = async () => {
  //     try {
  //       const result = await ServiceCall.gett(
  //         `/v2/patient/open/list/available/doctor/${mode}?som=${code}`
  //       );
  //       const dataa = result.data.doctors;
  //      setDetails(dataa);
  //     } catch (err) {}
  //   };
  //   data();
  // }, [code]);
  const isMobile = useMediaQuery("(max-width:710px)");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleID = (res, event) => {
    setDoctorID(res.doc_id);
    setHospitalID(res.hos_id);
    setDoctorDetails(res);
    setPerson(event);

    if (res.appointment_booking_button_title === "Call for Appointment") {
      const appointmentPhone = res.appointment_phone;
      window.open(`tel:${appointmentPhone}`);
    } else if (res.appointment_booking_button_title === "Book Appointment") {
      console.log("Opening modal for booking appointment");
      const url = `${book_Appointment}${res.route_key}`;
      setIframeUrl(url);
      setShowModal(true);
    } else {
      handleShow();
    }
  };

  const searchData = (e) => {
    e.preventDefault();
    if (search.trim() !== "") {
      const filteredDoctors = details.filter((doctor) =>
        doctor.som.toLowerCase().includes(search.toLowerCase())
      );
      // setDetails(filteredDoctors);
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    if (value == 1) {
      const sort = [...details].sort(function (a, b) {
        return b.experience - a.experience;
      });
      setDetails(sort);
    } else if (value == 2) {
      const sort = [...details].sort(function (a, b) {
        return a.experience - b.experience;
      });
      setDetails(sort);
    } else if (value == 3) {
      const sort = [...details].sort(function (a, b) {
        return a.doctor_name.toLowerCase() > b.doctor_name.toLowerCase()
          ? 1
          : -1;
      });
      setDetails(sort);
    }
  };
  const doctorpro = (res) => {
    const profileUrl = `/profile/${res.route_key}`; // Include route_key in the URL

    // Navigate to the profile page with state
    navigate(profileUrl);
    // console.log("speciality", res)
  };
  const unicode = "\u20B9";

  const handleregis = () => {
    navigate("/treatment-advice");
  };

    // Function to fetch doctors based on the current page
    const fetchDoctors = async (currentPage) => {
      setLoading(true);
      try {
        const response = await ServiceCall.gett(
          `/v2/patient/open/pagination/list/available/doctor/${mode}/${currentPage}/${limit}?som=${selectedFilter}`
        );
        const newDoctors = response.data.doctors;
  
        // If fewer doctors than the limit are returned, no more data is available
        if (newDoctors.length < limit) {
          setHasMore(false);
        }
  
        setDetails((prevDoctors) => [...prevDoctors, ...newDoctors]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching doctors:", error);
        setLoading(false);
      }
    };
  
    // Load the next page when user scrolls near the bottom
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = window.innerHeight;
  
      if (scrollTop + clientHeight >= scrollHeight - 100 && !loading && hasMore) {
        setPage((prevPage) => prevPage + 1); // Increment page to load more doctors
      }
    };
  
    // Fetch doctors when page changes
    useEffect(() => {
      if (hasMore) {
        fetchDoctors(page);
      }
    }, [page]);
  
    // Attach scroll event listener
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
  
      // Cleanup on component unmount
      return () => window.removeEventListener("scroll", handleScroll);
    }, [loading, hasMore]);



  useEffect(() => {
    const lookups = async () => {
      try {
        const lookupdata = await ServiceCall.get(
          "/v2/facility/som/open/lookup"
        );
        if (lookupdata) {
          setLookup(lookupdata.data.records);
        }
      } catch (err) {}
    };
    lookups();
  }, []);

  const handleFilterChange = async (e) => {
    const selectedCode = e.target.value; // Get the selected code
    setSelectedFilter(selectedCode);
    setPage(1); // Reset to the first page when filter changes
    setDetails([]); // Clear the existing doctors list for the new filter
    setHasMore(true); // Reset the flag for more doctors
  
    if (selectedCode) {
      try {
        // Make an API call with the selected code, current page, and limit
        const response = await ServiceCall.gett(
          `/v2/patient/open/pagination/list/available/doctor/${mode}/${page}/${limit}?som=${selectedCode}`
        );
        const newDoctors = response.data.doctors;
  
        if (newDoctors.length < limit) {
          setHasMore(false); // No more doctors if less than limit is returned
        }
  
        setDetails((prevDoctors) => [...prevDoctors, ...newDoctors]);
        console.log("API Response:", response.data); // Handle the response as needed
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  

  return (
    <>
      {/* <!-- Banner Section --> */}
      <section className="ms-book-banner d-flex align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="offset-md-3 col-md-6" style={{ height: "105px" }}>
              <h3 className="mb-3 ms-font-weight-600 text-center">
                Search Specialists
              </h3>
              <form
                onSubmit={searchData}
                className="position-relative ms-form-layout d-flex"
              >
                {/* Dropdown for selecting category */}
                <select
                  className="form-select me-2"
                  onChange={handleFilterChange} // Call handleFilterChange when selection is made
                  value={selectedFilter}
                  style={{ width: "30%",  marginTop: isMobile ? "8px" : "" }}
                >
                  <option value="">Select Speciality</option>
                  {lookup.map((item) => (
                    <option key={item.code} value={item.code}>
                      {item.title} {/* Map the title from the fetched data */}
                    </option>
                  ))}
                </select>

                {/* Input field for search */}
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Speciality, Doctors, Clinics"
                  style={{ marginTop: isMobile ? "8px" : "" }}
                />

                {/* Submit button */}
                <button
                  style={{ height: "100%", marginLeft: isMobile? "-7px" : "10px", borderRadius: "0" }}
                  type="submit"
                  className="ms-secondary-btn mt-2 mt-md-0"
                >
                  <i className="fas"></i> Search
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Appointment Section --> */}
      <span
        style={{
          display: "block",
          width: "100vw", // Use 100vw for full viewport width
          backgroundColor: "rgb(0 92 116)",
          color: "#ffffff",
          padding: "6px",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "21px",
          // marginTop: "53px",
          position: "relative", // Add position relative
          left: "50%", // Move the element 50% to the left
          right: "50%", // Move the element 50% to the right
          marginLeft: "-50vw", // Compensate the left movement
          marginRight: "-50vw", // Compensate the right movement
        }}
      >
        LIMITED TIME OFFER! Get{" "}
        <span style={{ color: "rgb(255, 203, 37)" }}>FREE</span> treatment
        advice from our experts
        <button className="handlrs" onClick={handleregis}>
          <span style={{ fontSize: "17px" }}>CONSULT NOW</span>
        </button>
      </span>
      {!show && (
        <section className="ms-appointment-section position-relative">
          <div className="container">
            <div className="row">
              <div className="col-md-12 p-4 bg-white">
                <div className="row mb-3">
                  <div className="col-6">
                    <h5 className="mb-1 ms-font-weight-600">All SPECIALISTS</h5>
                    <p className="ms-third-color mb-0">{details.length} Matches Found</p>
                  </div>
                  <div className="col-6 text-end">
                    <p className="ms-third-color mb-1 ms-dark-color fw-normal ms-sort-txt">Sort by:</p>
                    <select onChange={(e) => handleChange(e)}>
                      <option value="0">None</option>
                      <option value="1">High to Low</option>
                      <option value="2">Low to High</option>
                      <option value="3">Name</option>
                    </select>
                  </div>
                </div>

                {details.length === 0 ? (
                  <div
                    style={{ width: "100%" }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <div className="spinner-border" role="status" style={{textAlign: "center"}}>
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  details
                    .filter((val) => {
                      if (search === "") {
                        return val;
                      } else if (val.doctor_name.toLowerCase().includes(search.toLowerCase())) {
                        return val;
                      }
                    })
                    .map((res, key) => (
                      <div key={key} className="ms-doctors" style={{ cursor: "pointer" }} onClick={() => doctorpro(res)}>
                        <div className="row">
                          <div className="col-lg-12 pe-0">
                            <div className="row mb-3">
                              <div className="col-sm-12 col-md-auto">
                                <img
                                  src={ServiceCall.images(`v2/patient/doctor/pic/${mode}/${res.doc_id}`)}
                                  className="img-fluid ms-doc-img"
                                  alt="Image"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => doctorpro(res)}
                                />
                              </div>
                              <div className="col-sm-12 col-md" style={{ textAlign: "left" }}>
                                <h1 className="ms-font-weight-600 mb-1" style={{ fontSize: "20px" }}>
                                  {res.doctor_name}
                                  <VisibilityIcon style={{ marginLeft: "10px" }} onClick={() => doctorpro(res)} />
                                </h1>
                                <h6 className="mb-0 text-break" style={{ fontWeight: "600" }}>
                                  {res.speciality}
                                </h6>
                                <p className="mb-0">{res.experience} Years Experience</p>
                                {res.consult_charges !== 0 && (
                                  <p
                                    className="mb-4"
                                    style={{ fontWeight: "600", fontSize: "23px", color: "black", marginRight: "107px" }}
                                  >
                                    {unicode}
                                    {res.consult_charges}
                                  </p>
                                )}
                              </div>

                              <div className="col-sm-12 col-md-3">
                                <div className="mb-3">
                                  {res.availability_today.clinic_start_time !== "" &&
                                    res.availability_today.clinic_end_time !== "" && (
                                      <p className="mb-0">
                                        <DirectionsWalkIcon style={{ color: "black" }} />
                                        <span style={{ color: "#413f3f", fontWeight: "500" }}>
                                          {res.availability_today?.clinic_days}
                                        </span>
                                        <br />
                                        {res.availability_today.clinic_start_time >= 12
                                          ? (24 - res.availability_today.clinic_start_time).toFixed(2)
                                          : res.availability_today.clinic_start_time}{" "}
                                        To{" "}
                                        {res.availability_today.clinic_end_time >= 12
                                          ? (24 - res.availability_today.clinic_end_time).toFixed(2)
                                          : res.availability_today.clinic_end_time}
                                      </p>
                                    )}
                                </div>
                                <div className="mb-3">
                                  {res.availability_today.online_start_time !== "" &&
                                    res.availability_today.online_end_time !== "" && (
                                      <p className="mb-0">
                                        <VideoCameraFrontIcon style={{ color: "black" }} />
                                        <span style={{ color: "#413f3f", fontWeight: "500" }}>
                                          {res.availability_today?.online_days}
                                        </span>
                                        <br />
                                        {res.availability_today.online_start_time >= 12
                                          ? (24 - res.availability_today.online_start_time).toFixed(2)
                                          : res.availability_today.online_start_time}{" "}
                                        To{" "}
                                        {res.availability_today.online_end_time >= 12
                                          ? (24 - res.availability_today.online_end_time).toFixed(2)
                                          : res.availability_today.online_end_time}
                                      </p>
                                    )}
                                </div>
                              </div>

                              <div className="col-sm-12 col-lg-3 pe-0">
                                <div className="d-flex" style={{ flexDirection: "column" }}>
                                  <div style={{ display: "flex", flexDirection: "column", marginTop: "-8px", maxWidth: "211px" }}>
                                    <button
                                      className="tele__btn ms-border-rounded ms-btn"
                                      type="button"
                                      style={{ backgroundColor: "#ffa000", color: "#fff", border: "2px solid #ffa000" }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleID(res, "True", "Clinic");
                                      }}
                                    >
                                      <a>{res.appointment_booking_button_title}</a>{" "}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {res.online_discount_stmt !== "Get upto 0% discount on advance booking!" && (
                            <div
                              style={{
                                backgroundColor: "#ffa000",
                                border: "3px solid #ffa000",
                                borderRadius: "5px",
                                width: "102%",
                              }}
                            >
                              <p style={{ fontSize: "18px", color: "#fff", textAlign: "center", marginBottom: "0px" }}>
                                <strong>{res.online_discount_stmt}</strong>
                              </p>
                            </div>
                          )}

                          <div className="col-md-7 col-lg-12" style={{ padding: "6px 20px", margin: "auto", marginBottom: "-23px" }}>
                            <p
                              className="mb-2"
                              style={{ fontSize: "18px", textAlign: "center", cursor: "pointer" }}
                              onClick={() => window.open(res.google_maps_url)}
                            >
                              <AddLocationOutlinedIcon className="ms-icon ms-second-color me-1" />
                              {res.hospital}, {res.address}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                )}

                {/* Show loading spinner when fetching more doctors */}
                {loading && (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}

                {/* Show message when no more doctors are available */}
                {!hasMore && <p style={{textAlign: "center", fontSize: "1rem", fontWeight: "500"}}>No more doctors to load.</p>}
              </div>
            </div>
          </div>
        </section>
      )}

      {/*................. Appointment tab toggle from here......................  */}

      {show && (
        <div className="appointment__screen">
          <AppointmentScreen
            doctor={doctorID}
            hospital={hospitalID}
            isPerson={showPerson}
            AllDoctorDetails={doctorDetails}
            close={handleClose}
          />
        </div>
      )}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{width: "35%", left: "30%"}}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Book Appointment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={iframeUrl}
            width="100%"
            height="650px"
            title="Appointment Booking"
            frameBorder="0"
            allowFullScreen
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Booking;
