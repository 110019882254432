import React from 'react';
import { Link } from 'react-router-dom';
import "../../Css/companypage.css"
function PrivacyPolicy() {
  return (  
    <>
        <section className="pp__first">
             <h1>Privacy Policy</h1>
            <p>Rx One Care Pvt. Ltd. (“us”, “we”, or “Rx One Care”, which also includes its affiliates) is the author and publisher of the internet resource www.rxone.app  (“Website”) on the world wide web as well as the software and applications provided by Rx One Care, including but not limited to the mobile application ‘Rx One Care’, and the software and applications of the brand names ‘Rx One’, ‘Rx One Care’, ‘Care Portal’, ‘Doctor Portal’, and ‘Rx Control Centre’ (together with the Website, referred to as the “Services”).</p>
            <br />
            <p>This privacy policy ("Privacy Policy") explains how we collect, use, share, disclose and protect Personal information about the Users of the Services, including the Practitioners (as defined in the Terms of Use, which may be accessed via the following weblink https://Rxone.app/privacy (the “Terms of Use”)), the End-Users (as defined in the Terms of Use), and the visitors of Website (jointly and severally referred to as “you” or “Users” in this Privacy Policy). We created this Privacy Policy to demonstrate our commitment to the protection of your privacy and your personal information. Your use of and access to the Services is subject to this Privacy Policy and our Terms of Use. Any capitalized term used but not defined in this Privacy Policy shall have the meaning attributed to it in our Terms of Use.</p>
            <br />
            <p>BY USING THE SERVICES OR BY OTHERWISE GIVING US YOUR INFORMATION, YOU WILL BE DEEMED TO HAVE READ, UNDERSTOOD AND AGREED TO THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY AND AGREE TO BE BOUND BY THE PRIVACY POLICY. YOU HEREBY CONSENT TO OUR COLLECTION, USE AND SHARING, DISCLOSURE OF YOUR INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY. WE RESERVE THE RIGHT TO CHANGE, MODIFY, ADD OR DELETE PORTIONS OF THE TERMS OF THIS PRIVACY POLICY, AT OUR SOLE DISCRETION, AT ANY TIME. IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY AT ANY TIME, DO NOT USE ANY OF THE SERVICES OR GIVE US ANY OF YOUR INFORMATION. IF YOU USE THE SERVICES ON BEHALF OF SOMEONE ELSE (SUCH AS YOUR CHILD) OR AN ENTITY (SUCH AS YOUR EMPLOYER), YOU REPRESENT THAT YOU ARE AUTHORISED BY SUCH INDIVIDUAL OR ENTITY TO (I) ACCEPT THIS PRIVACY POLICY ON SUCH INDIVIDUAL’S OR ENTITY’S BEHALF, AND (II) CONSENT ON BEHALF OF SUCH INDIVIDUAL OR ENTITY TO OUR COLLECTION, USE AND DISCLOSURE OF SUCH INDIVIDUAL’S OR ENTITY’S INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY.</p>

            <div className="pp__container">
                <ol type="1" >

              {/* ------- why Privacy Policy --------       */}

                    <li> <strong>WHY THIS PRIVACY POLICY</strong> 
                     <br />
                     <br />
                     <p>This Privacy Policy is published in compliance with, inter alia:</p>
                     <ol type="I" className="pp__listGap" >
                         <li>Section 43A of the Information Technology Act, 2000;</li>
                         <li>Regulation 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (the “SPI Rules”);</li>
                         <li>Regulation 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011.</li>
                         <br />
                         <p><strong>This Privacy Policy states the following:</strong></p>
                         <li>The type of information collected from the Users, including Personal Information (as defined in paragraph 2 below) and Sensitive Personal Data or Information (as defined in paragraph 2 below) relating to an individual;</li>
                         <li>The purpose, means and modes of collection, usage, processing, retention and destruction of such information; and</li>
                         <li>How and to whom Rx One Care will disclose such information.</li>
                     </ol>
                    </li>
                    <br />
     {/* ------------------ Collect person Information --- -- ------  */}

                    <li> <strong>COLLECTION OF PERSONAL INFORMATION</strong> 
                    <p>Generally some of the Services require us to know who you are so that we can best meet your needs. When you access the Services, or through any interaction with us via emails, telephone calls or other correspondence, we may ask you to voluntarily provide us with certain information that personally identifies you or could be used to personally identify you. You hereby consent to the collection of such information by Rx One Care. Without prejudice to the generality of the above, information collected by us from you may include (but is not limited to) the following:</p>
                    <ol type='I'  className="pp__listGap">
                        <li>contact data (such as your email address and phone number);</li>
                        <li>demographic data (such as your gender, your date of birth and your pin code);</li>
                        <li>data regarding your usage of the services and history of the appointments made by or with you through the use of Services;</li>
                        <li>insurance data (such as your insurance carrier and insurance plan);</li>
                        <li>other information that you voluntarily choose to provide to us (such as information shared by you with us through emails or letters.</li>
                        <br />
                    <p>The information collected from you by Rx One Care may constitute ‘personal information’ or ‘sensitive personal data or information’ under the SPI Rules.</p>
                    <p>“Personal Information” is defined under the SPI Rules to mean any information that relates to a natural person, which, either directly or indirectly, in combination with other information available or likely to be available to a body corporate, is capable of identifying such person.The SPI Rules further define “Sensitive Personal Data or Information” of a person to mean information about that person relating to:</p>
                    <li>passwords;</li>
                    <li>financial information such as bank accounts, credit and debit card details or other payment instrument details;</li>
                    <li>physical, physiological and mental health condition;</li>
                    <li>sexual orientation;</li>
                    <li>medical records and history;</li>
                    <li>biometric information;</li>
                    <li>information received by body corporate under lawful contract or otherwise;call data records.</li>
                    <br />
                    <p>Rx One Care will be free to use, collect and disclose information that is freely available in the public domain without your consent</p>
                    </ol>
                    </li>
                    <br />

                    <li> <strong>PRIVACY STATEMENTS</strong> 
                    <ol type='I' className='pp__PS'>
                        <li><p ><span>3.1</span>  ALL USERS NOTE:</p>
                        <p>This section applies to all users.</p>
                        <p><span>3.1.1</span>  Accordingly, a condition of each User’s use of and access to the Services is their acceptance of the Terms of Use, which also involves acceptance of the terms of this Privacy Policy. Any User that does not agree with any provisions of the same has the option to discontinue the Services provided by Rx One Care immediately.</p>
                        <p><span>3.1.2</span>  An indicative list of information that Rx One Care may require you to provide to enable your use of the Services is provided in the Schedule annexed to this Privacy Policy.</p>
                        <p><span>3.1.3</span>  All the information provided to Rx One Care by a User, including Personal Information or any Sensitive Personal Data or Information, is voluntary. You understand that Rx One Care may use certain information of yours, which has been designated as Personal Information or ‘Sensitive Personal Data or Information’ under the SPI Rules, (a) for the purpose of providing you the Services, (b) for commercial purposes and in an aggregated or non-personally identifiable form for research, statistical analysis and business intelligence purposes, (c) for sale or transfer of such research, statistical or intelligence data in an aggregated or non-personally identifiable form to third parties and affiliates (d) for communication purpose so as to provide You a better way of booking appointments and for obtaining feedback in relation to the Practitioners and their practice, (e) debugging customer support related issues.. (f) for the purpose of contacting you to complete any transaction if you do not complete a transaction after having provided us with your contact information in the course of completing such steps that are designed for completion of the transaction. Rx One Care also reserves the right to use information provided by or about the End-User for the following purposes:</p>
                        <ol type='I' className='pp__listGap'>
                        <li>Publishing such information on the Website.</li>
                        <li>Contacting End-Users for offering new products or services.</li>
                        <li>Contacting End-Users for taking product and Service feedback.</li>
                        <li>Analyzing software usage patterns for improving product design and utility.</li>
                        <li>Analyzing anonymized practice information for commercial use.</li>
                        <li>Processing payment instructions including those through independent third party service providers such as payment gateways, banking and financial institutions, pre-paid instrument and wallet providers for processing of payment transaction or deferral of payment facilities.</li>
                        </ol>
                        <br />
                        <p>If you have voluntarily provided your Personal Information to Rx One Care for any of the purposes stated above, you hereby consent to such collection and use of such information by Rx One Care. However, Rx One Care shall not contact You on Your telephone number(s) for any purpose including those mentioned in this sub-section 4.1(iii), if such telephone number is registered with the Do Not Call registry (“DNC Registry”) under the PDPA without your express, clear and un-ambiguous written consent.</p>
                        <p><span>3.1.4</span>  Collection, use and disclosure of information which has been designated as Personal Information or Sensitive Personal Data or Information’ under the SPI Rules requires your express consent. By affirming your assent to this Privacy Policy, you provide your consent to such use, collection and disclosure as required under applicable law.</p>
                        <p><span>3.1.5</span>  Rx One Care does not control or endorse the content, messages or information found in any Services and, therefore, Rx One Care specifically disclaims any liability with regard to the Services and any actions resulting from your participation in any Services, and you agree that you waive any claims against Rx One Care relating to same, and to the extent such waiver may be ineffective, you agree to release any claims against Rx One Care relating to the same.</p>
                        <p><span>3.1.6</span>  You are responsible for maintaining the accuracy of the information you submit to us, such as your contact information provided as part of account registration. If your personal information changes, you may correct, delete inaccuracies, or amend information by making the change on our member information page or by contacting us through <Link to="/"><Link to="/">contact_us@rxone.app</Link></Link> . We will make good faith efforts to make requested changes in our then active databases as soon as reasonably practicable. If you provide any information that is untrue, inaccurate, out of date or incomplete (or becomes untrue, inaccurate, out of date or incomplete), or Rx One Care has reasonable grounds to suspect that the information provided by you is untrue, inaccurate, out of date or incomplete, Rx One Care may, at its sole discretion, discontinue the provision of the Services to you. There may be circumstances where Rx One Care will not correct, delete or update your Personal Data, including (a) where the Personal Data is opinion data that is kept solely for evaluative purpose; and (b) the Personal Data is in documents related to a prosecution if all proceedings relating to the prosecution have not been completed.</p>
                        <p><span>3.1.7</span>  If you wish to cancel your account or request that we no longer use your information to provide you Services, contact us through  <Link to="/">contact_us@rxone.app</Link>. We will retain your information for as long as your account with the Services is active and as needed to provide you the Services. We shall not retain such information for longer than is required for the purposes for which the information may lawfully be used or is otherwise required under any other law for the time being in force. After a period of time, your data may be anonymized and aggregated, and then may be held by us as long as necessary for us to provide our Services effectively, but our use of the anonymized data will be solely for analytic purposes. Please note that your withdrawal of consent, or cancellation of account may result in Rx One Care being unable to provide you with its Services or to terminate any existing relationship Rx One Care may have with you.</p>
                        <p><span>3.1.8</span>  If you wish to opt-out of receiving non-essential communications such as promotional and marketing-related information regarding the Services, please send us an email at <Link to="/">contact_us@rxone.app</Link> .</p>
                        <p><span>3.1.10</span>  Due to the communications standards on the Internet, when a User or the End-User or anyone who visits the Website, Rx One Care automatically receives the URL of the site from which anyone visits. Rx One Care also receives the Internet Protocol (IP) address of each User’s computer (or the proxy server a User used to access the World Wide Web), User’s computer operating system and type of web browser the User is using, email patterns, as well as the name of User’s ISP. This information is used to analyze overall trends to help Rx One Care improve its Service. The linkage between User’s IP address and User’s personally identifiable information is not shared with or disclosed to third parties. Notwithstanding the above, Rx One Care may share and/or disclose some of the aggregate findings (not the specific data) in anonymized form (i.e., non-personally identifiable) with advertisers, sponsors, investors, strategic partners, and others in order to help grow its business..</p>
                        <p><span>3.1.11</span>  The Website uses temporary cookies to store certain (that is not sensitive personal data or information) that is used by Rx One Care and its service providers for the technical administration of the Website, research and development, and for User administration. In the course of serving advertisements or optimizing services to its Users, Rx One Care may allow authorized third parties to place or recognize a unique cookie on the User’s browser. The cookies however, do not store any Personal Information of the User. You may adjust your internet browser to disable cookies. If cookies are disabled you may still use the Website, but the Website may be limited in the use of some of the features.</p>
                        <p><span>3.1.12</span> A User may have limited access to the Website without creating an account on the Website. Unregistered Users can make appointments with the doctors by providing their name and phone number. In order to have access to all the features and benefits on our Website, a User must first create an account on our Website. To create an account, a User is required to provide the following information, which such User recognizes and expressly acknowledges is Personal Information allowing others, including Rx One Care, to identify the User: name, User ID, email address, country, ZIP/postal code, age, phone number, password chosen by the User and valid financial account information. Other information requested on the registration page, including the ability to receive promotional offers from Rx One Care, is optional. Rx One Care may, in future, include other optional requests for information from the User to help Rx One Care to customize the Website to deliver personalized information to the User.</p>
                        <p><span>3.1.13</span>  This Privacy Policy applies to Services that are owned and operated by Rx One Care. Rx One Care does not exercise control over the sites displayed as search results or links from within its Services. These other sites may place their own cookies or other files on the Users’ computer, collect data or solicit personal information from the Users, for which Rx One Care is not responsible or liable. Accordingly, Rx One Care does not make any representations concerning the privacy practices or policies of such third parties or terms of use of such websites, nor does Rx One Care guarantee the accuracy, integrity, or quality of the information, data, text, software, sound, photographs, graphics, videos, messages or other materials available on such websites. The inclusion or exclusion does not imply any endorsement by Rx One Care of the website, the website's provider, or the information on the website. If you decide to visit a third party website linked to the Website, you do this entirely at your own risk. Rx One Care encourages the User to read the privacy policies of that website.</p>
                        <p><span>3.1.14</span>   The Website may enable User to communicate with other Users or to post information to be accessed by others, whereupon other Users may collect such data. Such Users, including any moderators or administrators, are not authorized Rx One Care representatives or agents, and their opinions or statements do not necessarily reflect those of Rx One Care, and they are not authorized to bind Rx One Care to any contract. Rx One Care hereby expressly disclaims any liability for any reliance or misuse of such information that is made available by Users or visitors in such a manner.</p>
                        <p><span>3.1.15</span>  
                         Rx One Care does not collect information about the visitors of the Website from other sources, such as public records or bodies, or private organisations, save and except for the purposes of registration of the Users (the collection, use, storage and disclosure of which each End User must agree to under the Terms of Use in order for Rx One Care to effectively render the Services).</p>
                        <p><span>3.1.16</span>  Rx One Care maintains a strict "No-Spam" policy, which means that Rx One Care does not intend to sell, rent or otherwise give your e-mail address to a third party without your consent.</p>
                        <p><span>3.1.17</span>  Rx One Care has implemented best international market practices and security policies, rules and technical measures to protect the personal data that it has under its control from unauthorised access, improper use or disclosure, unauthorised modification and unlawful destruction or accidental loss. However, for any data loss or theft due to unauthorized access to the User’s electronic devices through which the User avails the Services, Rx One Care shall not be held liable for any loss whatsoever incurred by the User.</p>
                        <p><span>3.1.18</span>  18Rx One Care implements reasonable security practices and procedures and has a comprehensive documented information security programme and information security policies that contain managerial, technical, operational and physical security control measures that are commensurate with respect to the information being collected and the nature of Rx One Care’s business.</p>
                        <p><span>3.1.19</span>  Rx One Care takes your right to privacy very seriously and other than as specifically stated in this Privacy Policy, will only disclose your Personal Information in the event it is required to do so by law, rule, regulation, law enforcement agency, governmental official, legal authority or similar requirements or when Rx One Care, in its sole discretion, deems it necessary in order to protect its rights or the rights of others, to prevent harm to persons or property, to fight fraud and credit risk, or to enforce or apply the Terms of Use.</p>

                        </li>
                        <li> <p><span>3.2 </span>PRACTITIONERS NOTE:</p>
                        <p>This section applies to all Practitioners/Health Care Providers.</p>
                        <p><span>3.2.1</span>  As part of the registration as well as the application creation and submission process that is available to Practitioners on Rx One Care, certain information, including Personal Information or Sensitive Personal Data or Information is collected from the Practitioners.</p>
                        <p><span>3.2.2</span>  All the statements in this Privacy Policy apply to all Practitioners, and all Practitioners are therefore required to read and understand the privacy statements set out herein prior to submitting any Personal Information or Sensitive Personal Data or Information to Rx One Care, failing which they are required to leave the Services, including the Website immediately.</p>
                        <p><span>3.3.3</span>  Practitioners’ personally identifiable information, which they choose to provide to Rx One Care, is used to help the Practitioners describe and identify themselves. This information is exclusively owned by Rx One Care You will be the owner of your information and you consent to Rx One Care collecting, using, processing and/or disclosing this information for the purposes hereinafter stated . Rx One Care may use such information for commercial purposes and in an aggregated or non-personally identifiable form for research, statistical analysis and business intelligence purposes, and may sell or otherwise transfer such research, statistical or intelligence data in an aggregated or non-personally identifiable form to third parties and affiliates. Rx One Care also reserves the right to use information provided by or about the Practitioner for the following purposes:</p>
                        <ol type='I'>
                        <li>Publishing such information on the Website.</li>
                        <li>Contacting Practitioners for offering new products or services subject to the telephone number registered with the DNC Registry.</li>
                        <li>Contacting Practitioners for taking product feedback.</li>
                        <li>Analyzing software usage patterns for improving product design and utility.</li>
                        <li>Analyzing anonymized practice information including financial, and inventory information for commercial use.</li>
                        </ol>
                        <p><span>3.2.4</span>  Rx One Care automatically enables the listing of Practitioners’ information on its Website for every ‘Doctor’ or ‘Clinic’ added to a Practice using its software. The Practitioner information listed on Website is displayed when End-Users search for doctors on Website, and the Practitioner information listed on Website is used by End-Users to request for doctor appointments. Any personally identifiable information of the Practitioners listed on the Website is not generated by Rx One Care and is provided to Rx One Care by Practitioners who wish to enlist themselves on the Website, or is collected by Rx One Care from the public domain. Rx One Care displays such information on its Website on an as-is basis making no representation or warranty on the accuracy or completeness of the information. As such, we strongly encourage Practitioners to check the accuracy and completeness of their information from time to time, and inform us immediately of any discrepancies, changes or updates to such information. Rx One Care will, however, take reasonable steps to ensure the accuracy and completeness of this information.</p>          
                        <p><span>3.2.5</span>  Rx One Care may also display information for Practitioners who have not signed up or registered for the Services, provided that the Practitioners have consented to Rx One Care collecting, processing and/or disclosing their information on the Website. Such Practitioners are verified by Rx One Care or its associates, and Rx One Care makes every effort to capture accurate information for such Practitioners. However, Rx One Care does not undertake any liability for any incorrect or incomplete information appearing on the Website for such Practitioners.</p>          
                        </li>
                        <li> <p><span>3.3</span>END-USERS NOTE:</p>
                        <p>This section applies to all End-Users.</p>
                        <p><span>3.3.1</span>  As part of the registration/application creation and submission process that is available to End-Users on this Website, certain information, including Personal Information or Sensitive Personal Data or Information is collected from the End-Users.</p>  
                        <p><span>3.3.2</span>  RAll the statements in this Privacy Policy apply to all End-Users, and all End-Users are therefore required to read and understand the privacy statements set out herein prior to submitting any Personal Information or Sensitive Personal Data or Information to Rx One Care, failing which they are required to leave the Rx One Care immediately.</p>  
                        <p><span>3.3.3</span>  If you have inadvertently submitted any such information to Rx One Care prior to reading the privacy statements set out herein, and you do not agree with the manner in which such information is collected, processed, stored, used or disclosed, then you may access, modify and delete such information by using options provided on the Website. In addition, you can, by sending an email to <Link to="/">contact_us@rxone.app</Link>, inquire whether Rx One Care is in possession of your personal data, and you may also require Rx One Care to delete and destroy all such information.</p>  
                        <p><span>3.3.4</span>  End-Users’ personally identifiable information, which they choose to provide on the Website is used to help the End-Users describe/identify themselves. Other information that does not personally identify the End-Users as an individual, is collected by Rx One Care from End-Users (such as, patterns of utilization described above) and is exclusively owned by Rx One Care. Rx One Care may also use such information in an aggregated or non-personally identifiable form for research, statistical analysis and business intelligence purposes, and may sell or otherwise transfer such research, statistical or intelligence data in an aggregated or non-personally identifiable form to third parties and affiliates. In particular, Rx One Care reserves with it the right to use anonymized End-User demographics information and anonymized End-User health information for the following purposes:</p>  
                        <ol type='I'>
                        <li>Analyzing software usage patterns for improving product design and utility.</li>
                        <li>Analyzing such information for research and development of new technologies.</li>
                        <li>Contacting Practitioners for taking product feedback.</li>
                        <li>Using analysis of such information in other commercial product offerings of Rx One Care.</li>
                        <li>Sharing analysis of such information with third parties for commercial use.</li>
                        </ol>
                        <p><span>3.3.5</span>  Rx One Care will communicate with the End-Users through email, phone and notices posted on the Website or through other means available through the service, including text and other forms of messaging. The End-Users can change their e-mail and contact preferences at any time by logging into their "Account" at www.Rxone.app and changing the account settings.</p>  
                        <p><span>3.3.6</span>  At times, Rx One Care conducts a User survey to collect information about End-Users' preferences. These surveys are optional and if End-Users choose to respond, their responses will be kept anonymous. Similarly, Rx One Care may offer contests to qualifying End-Users in which we ask for contact and demographic information such as name, email address and mailing address. The demographic information that Rx One Care collects in the registration process and through surveys is used to help Rx One Care improve its Services to meet the needs and preferences of End-Users.</p> 
                        <p><span>3.3.7</span>  Rx One Care may keep records of electronic communications and telephone calls received and made for making appointments or other purposes for the purpose of administration of Services, customer support, research and development and for better listing of Practitioners.</p> 
                        <p><span>3.3.8</span> All Rx One Care employees and data processors, who have access to, and are associated with the processing of sensitive personal data or information, are obliged to respect the confidentiality of every End-Users’ Personal Information or Sensitive Personal Data and Information. Rx One Care has put in place procedures and technologies as per good industry practices and in accordance with the applicable laws, to maintain security of all personal data from the point of collection to the point of destruction. Any third-party data processor to which Rx One Care transfers Personal Data shall have to agree to comply with those procedures and policies, or put in place adequate measures on their own.</p> 
                        <p><span>3.3.9</span> Rx One Care may also disclose or transfer End-Users’ personal and other information provided by a User, to a third party as part of reorganization or a sale of the assets of a Rx One Care corporation division or company. Any third party to which Rx One Care transfers or sells its assets to will have the right to continue to use the personal and other information that End-Users provide to us, in accordance with the Terms of Use</p> 
                        <p><span>3.3.10</span> To the extent necessary to provide End-Users with the Services, Rx One Care may provide their Personal Information to third party contractors who work on behalf of or with Rx One Care to provide End-Users with such Services, to help Rx One Care communicate with End-Users or to maintain the Website or independent third party service providers to process payment instructions including providing a payment deferral facility to End-Users in relation to the Services. These third-party service providers have access to information needed to process payments, but may not use it for other purposes. Generally these contractors do not have any independent right to share this information, however certain contractors who provide services on the Website, including the providers of online communications services, may use and disclose the personal information collected in connection with the provision of these Services in accordance with their own privacy policies. In such circumstances, you consent to us disclosing your Personal Information to contractors, solely for the intended purposes only.</p> 
                        </li>
                        <li><p><span>3.4</span> CASUAL VISITORS NOTE:</p>
                        <p><span>3.4.1</span> No sensitive personal data or information is automatically collected by Rx One Care from any casual visitors of this website, who are merely perusing the Website.</p>   
                        <p><span>3.4.2</span> Nevertheless, certain provisions of this Privacy Policy are applicable to even such casual visitors, and such casual visitors are also required to read and understand the privacy statements set out herein, failing which they are required to leave this Website immediately.</p>   
                        <p><span>3.4.3</span> If you, as a casual visitor, have inadvertently browsed any other page of this Website prior to reading the privacy statements set out herein, and you do not agree with the manner in which such information is obtained, collected, processed, stored, used, disclosed or retained, merely quitting this browser application should ordinarily clear all temporary cookies installed by Rx One Care. All visitors, however, are encouraged to use the “clear cookies” functionality of their browsers to ensure such clearing / deletion, as Rx One Care cannot guarantee, predict or provide for the behaviour of the equipment of all the visitors of the Website.</p>   
                        <p><span>3.4.4</span> You are not a casual visitor if you have willingly submitted any personal data or information to Rx One Care through any means, including email, post or through the registration process on the Website. All such visitors will be deemed to be, and will be treated as, Users for the purposes of this Privacy Policy, and in which case, all the statements in this Privacy Policy apply to such persons.</p> 
                        </li>
                    </ol>
                    </li>

                    <br />
                    <li> <strong>CONFIDENTIALITY AND SECURITY</strong> 
                      <br />
                      <br />
                    <ol className='pp__cas'>
                        <li><p><span>4.1</span>Your Personal Information is maintained by Rx One Care in electronic form on its equipment, and on the equipment of its employees. Such information may also be converted to physical form from time to time. Rx One Care takes all necessary precautions to protect your personal information both online and off-line, and implements reasonable security practices and measures including certain managerial, technical, operational and physical security control measures that are commensurate with respect to the information being collected and the nature of Rx One Care’s business.</p></li>
                        <li><p><span>4.2</span>No administrator at Rx One Care will have knowledge of your password. It is important for you to protect against unauthorized access to your password, your computer and your mobile phone. Be sure to log off from the Website when finished. Rx One Care does not undertake any liability for any unauthorised use of your account and password. If you suspect any unauthorized use of your account, you must immediately notify Rx One Care by sending an email to support@Rxone.app You shall be liable to indemnify Rx One Care due to any loss suffered by it due to such unauthorized use of your account and password.</p></li>
                        <li><p><span>4.3</span>Rx One Care makes all User information accessible to its employees, agents or partners and third parties only on a need-to-know basis, and binds only its employees to strict confidentiality obligations.</p></li>
                        <li><p><span>4.4</span>Part of the functionality of Rx One Care is assisting the doctors to maintain and organise such information. Rx One Care may, therefore, retain and submit all such records to the appropriate authorities, or to doctors who request access to such information.</p></li>
                        <li><p><span>4.5</span>Part of the functionality of the Rx One Care is assisting the patients to access information relating to them. Rx One Care may, therefore, retain and submit all such records to the relevant patients, or to their doctors.</p></li>
                        <li><p><span>4.6</span>Notwithstanding the above, Rx One Care is not responsible for the confidentiality, security or distribution of your Personal Information by our partners and third parties outside the scope of our agreement with such partners and third parties. Further, Rx One Care shall not be responsible for any breach of security or for any actions of any third parties or events that are beyond the reasonable control of Rx One Care including but not limited to, acts of government, computer hacking, unauthorised access to computer data and storage device, computer crashes, breach of security and encryption, poor quality of Internet service or telephone service of the User etc.</p></li>
                    </ol>
                    </li>
                    <br />
                    <li> <strong>CHANGE TO PRIVACY POLICY</strong> 
                    <p>Rx One Care may update this Privacy Policy at any time, with or without advance notice. In the event there are significant changes in the way Rx One Care treats User’s personally identifiable information, or in the Privacy Policy itself, Rx One Care will display a notice on the Website or send Users an email, as provided for above, so that you may review the changed terms prior to continuing to use the Services. As always, if you object to any of the changes to our terms, and you no longer wish to use the Services, you may contact support@Rxone.app to deactivate your account. Unless stated otherwise, Rx One Care’s current Privacy Policy applies to all information that Rx One Care has about you and your account.If a User uses the Services or accesses the Website after a notice of changes has been sent to such User or published on the Website, User hereby provides his/her/its consent to the changed terms.</p>
                    </li>
                    <br />
                    <li> <strong>CHliDREN'S AND MINOR'S PRIVACY</strong> 
                    <p>Rx One Care strongly encourages parents and guardians to supervise the online activities of their minor children and consider using parental control tools available from online services and software manufacturers to help provide a child-friendly online environment. These tools also can prevent minors from disclosing their name, address, and other personally identifiable information online without parental permission. Although the Rx One Care Website and Services are not intended for use by minors, Rx One Care respects the privacy of minors who may inadvertently use the internet or the mobile application.</p>
                    </li>
                    <br />
                    <li> <strong>CONSENT TO THIS POLICY</strong>
                    <p>You acknowledge that this Privacy Policy is a part of the Terms of Use of the Website and the other Services, and you unconditionally agree that becoming a User of the Website and its Services signifies your (i) assent to this Privacy Policy, and (ii) consent to Rx One Care using, collecting, processing and/or disclosing your Personal Information in the manner and for the purposes set out in this Privacy Policy. Your visit to the Website and use of the Services is subject to this Privacy Policy and the Terms of Use.</p>
                    </li>
                    <br />
                    <li><strong>ADDRESS FOR PRIVACY QUESTIONS</strong>
                    
                    <p>Should you have questions about this Privacy Policy or Rx One Care's information collection, use and disclosure practices, you may contact, the Data Protection Officer appointed by Rx One Care in accordance with the provisions of PDPA. We will use reasonable efforts to respond promptly to any requests, questions or concerns, which you may have regarding our use of your personal information. If you have any grievance with respect to our use of your information, you may communicate such grievance to the Data Protection Officer:
                    <br />
                    Name: Shruti Guru
                    <br />
                     Rx One Care Pvt Ltd
                     Hartron Innovation Centre, Plot No1, Udyog Vihar, Phase I, Dundahera Village, Sector 20, Gurugram, Haryana 122022, India
                    <br />
                    Haryana - 122022.
                    <br />
                    Phone: +917703934446
                    <br />
                    Email: <Link to="/">privacy@rxone.app</Link></p>
                    </li>
                </ol>

                  <div className='schdule'>
                   <h3>SCHEDULE</h3>
                   <h4>Indicative List of Information by Nature of Service</h4>
                   <ul>
                       <li><strong>End-Users using the Website by registering for an account on the Website or ‘Rx One Care’ mobile application:</strong>
                       <p>You can create an account by giving us information regarding your [name, mobile number, email address], and such other information as requested on the End-User registration page. This is to enable us to provide you with the facility to use the account to book your appointments and store other health related information.Against every Practitioner listed in rxone.app, you may see a ‘show number’ option. When you choose this option, you choose to call number through a free telephony service provided by Rx One Care, and the records of such calls are recorded and stored in Rx Labs’s servers. Such call will have an IVR message stating the purpose of recording your calls and your consent to such recordings are dealt with as per the Privacy Policy. If you choose not to consent to recording of such call, Rx One Care hereby reserves right to not provide you the Services for which such Personal Information is sought. Such records are dealt with only in accordance this Privacy Policy.</p></li>
                       <li><strong>End-Users using the Website without registering for an account on the Website or ‘Rx One Care’ mobile application (i.e., ‘Guest’ End-User):</strong>
                       <p>You can use the Website without registering for an account, but to book an appointment, you may be asked certain information (including your [mobile number], and such other information as requested when you choose to use the Services without registration) to confirm the appointment.</p>
                       <br />
                       <p>Against every Practitioner listed in Rxone.app, you may see a ‘show number’ option. When you choose this option, you choose to call the number through a free telephony service provided by Rx One Care, and the records of such calls are recorded and stored in Rx One Care’s servers. Such call will have an IVR message stating the purpose of recording your calls and your consent to such recordings which are dealt with as per the Privacy Policy. If you choose not to consent to recording of such call, Rx One Care hereby reserves the right to not provide you the Services for which such Personal Information is sought. Such records are dealt with only in accordance with this Privacy Policy.</p>
                       </li>
                       <li><strong>3.Practitioner availing of the free listing service on the Website or ‘RxOne’ PWA application by registering for an account:</strong>
                        <p>As a Practitioner/Provider, you may be required to provide us with information regarding your [name, mobile number, email address], and such other information as requested on the Provider’s registration page to create an account. Rx One Care may send email and/or SMS confirmations or other communications to End-Users in connection with their bookings, appointments or other interactions with you, if such interactions have been facilitated by Rx One Care.</p>
                       </li>
                       <li><strong>Practitioner availing of the free listing service on the Website or ‘RxOne’ PWA application without registering for an account:</strong>
                       <p>As a Practitioner, you may avail of the listing service without registering for an account by providing information regarding your [name, mobile number, email address], and such other information as requested by any of Rx One Care’s employees or agents who contact you in person or by telephone. In such event, Rx One Care will maintain this information if and until you choose to register for an account, for which Rx One Care may contact you from time to time. Rx One Care will, after such information is collected from you, send you a confirmation email confirming the information provided and the fact that you will be listed on the Website. In the event you do not wish to be so listed on the Website, please inform Rx One Care immediately at support@Rxone.app</p>
                       </li>
                       <li><strong>Practitioners using the ‘Doctor Portal’ and/or ‘Rx One Care’ products:</strong>
                       <p>You will be required to create an account and may be required to provide Rx One Care with information regarding your [name, mobile number, email address, digital signature], and such other information as requested by Rx One Care on the Doctor Portal and/or Rx One Care Practitioner registration page, in order to complete your registration. Practitioner agrees to the use of the digital signature in the prescription and clinical notes as the regulatory requirements. Rx One Care will not access the said digital signature for any other purpose under any circumstance. Upon registration, Rx One Care will access non-personally identifiable information of your patients from your patient records. You agree to make your patients fully aware of such access.</p>
                       <br />
                       <p>Rx One Care reserves the right to extend and withdraw ‘Appointment Booking’ functionality to you at its sole discretion, based on the number of End-User appointments being honoured by you. The extension or withdrawal of such facility shall be intimated to you by Rx One Care.</p>
                       <br />
                       <p>You have an option under these products to switch on ‘End-User Feedback’. This will mean that you are giving one or more patients’ contact details to Rx One Care’s feedback system. End-Users may choose to send feedback anonymously too, in which case you agree that you have no objection to such anonymous feedback. The feedback system will then send an SMS and email to the patient(s) asking for feedback which may then be published on the Website. You agree to make your patients fully aware of the possibility of their receiving such feedback queries.</p>
                       </li>
                       <li><strong>Practitioners using the ‘Rx One Care Campaign’ product:</strong>
                       <p>You will be required to create an account and may be required to provide Rx One Care with information regarding your [name, mobile number, email address], and such other information as requested by Rx One Care on the ‘Rx One Care Campaign’ Practitioner registration page, in order to complete your registration.</p>
                       </li>
                       <li><strong>End-Users and Practitioners using the Consult platform.</strong>
                       <p>You may be required to create an account and may be required to provide Rx One Care with information such as your name, mobile number, email address, and such other information as requested by Rx One Care on the RxOne Consult registration page in order to complete your registration.</p>
                       </li>
                   </ul>
                  </div>

            </div>
        </section>
    </>
  );
}

export default PrivacyPolicy;
