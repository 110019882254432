import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "../Css/home.css";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import logo from "../Images/SVG/fav icon.svg";
import Image1 from "../Images/33.png";
import BlogPost from "./BlogPost";
import testImage from "../Images/bnr-img.png";
import bannerImg2 from "../Images/home/slider img.png";
import bannerImg1 from "../Images/provider/man-girl-doctor-are-smiling-together.png";
import bannerImg3 from "../Images/44.png";
import homeIcon1 from "../Images/icons/rx one icons (5).svg";
import homeIcon2 from "../Images/icons/rx one icons (2).svg";
import homeIcon3 from "../Images/icons/rx one icons (3).svg";
import Appointment from "../Images/Check_1.gif";
import Medicine from "../Images/Heart_beat_1.gif";
import Lab from "../Images/Medicine_book_1.gif";
import connect from "../Images/screen.png";
import Image2 from "../Images/home/tab-mockup.png";
import Image3 from "../Images/home/background-img.png";
import Blog__1 from "../Images/blog-1.jpg";
import himaniNarula from "../Images/doctorsImages/Dr.HimaniNarula.jpg";
import parsoonPathak from "../Images/doctorsImages/Dr.PrasoonPathak.jpg";
import Boy from "../Images/boy.jpg";
import Blog__2 from "../Images/blog-2.jpg";
import Girl from "../Images/girl.jpg";
import CancelIcon from "@mui/icons-material/Cancel";
import { Carousel } from "react-bootstrap";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ServiceCall from "../ServiceCall";
import ladypic from "../Images/Lady.png";
import drkamal from "../Images/johny.png";
import drjp from "../Images/tony.jpg";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import statsappoint from "../Images/care.png";
import Treatment from "../Images/Treatments.png";
import Confuse from "../Images/confusedboy.gif";
import aboy from "../Images/looking-around-loop-ezgif.com-gif-maker.gif";
import doctorwrite from "../Images/doctorwrite.gif";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import consultpic from "../Images/consult.png";
import { useMediaQuery } from "@material-ui/core";

function Home() {
  const bannerImages = [Image1, bannerImg1, bannerImg2, bannerImg3];
  const isMobile = useMediaQuery("(max-width:710px)");

  const [index, setIndex] = useState(0);
  const [isArticalData, setArticalData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleSelects = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };
  const [show, setShow] = useState(false);
  const [blogpost, setBlogpost] = useState(false);
  const [blogData, setBlogData] = useState();
  const [isIframe, setIsIframe] = useState(false);
  const [Url, setUrl] = useState("");
  const [cdata, setCdata] = useState([]);
  const [isStuck, setIsStuck] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);

  const headings = [
    "Care to Cure - One App for All Care Needs",
    "Consult with Top Specialists",
    "Securely manage health records",
    "Discover Care Path ",
  ];

  // const [swap, setSwap] = useState("")
  /**** ***** THESE TWO ARE FOR I FRAME ******** */
  const [loading, stillLoading] = useState(true);
  /**** ***** THESE TWO ARE FOR I FRAME ******** */
  const carouselRef = useRef(null);

  const [nav, isNav] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    setIsStuck(false); // Set isStuck to false to remove the video
  };

  const handleMoveLeft = () => {
    carouselRef.current.prev();
  };

  const handleMoveRight = () => {
    carouselRef.current.next();
  };

  const transitionNav = () => {
    if (window.scrollY > 600 && window.scrollY < 3200) {
      isNav(true);
    } else {
      isNav(false);
    }
  };

  function truncate(string, max) {
    return string.length > max ? string.substr(0, max - 1) + "..." : string;
  }
  const navigation = (items) => {
    //  setIsIframe(true)
    // handleShow()
    // setUrl(e)
    navigate(`/blogs/${items.slug}`);
    setBlogpost(true);
    setBlogData(items);
  };

  const navigateTop = () => {
    window.scrollTo(0, 0);
  };

  // ***************** Fetching the Artical Section Data ******************************
  useEffect(() => {
    window.addEventListener("scroll", transitionNav);
    return () => window.addEventListener("scroll", transitionNav);
  });

  useEffect(() => {
    ArticalData();
    AOS.init({ duration: 1000 });
    // i use AOS library for animaton
  }, []);

  // console.log(isArticalData)

  useEffect(() => {
    const carddata = async () => {
      try {
        const cards = await ServiceCall.gett("/rxone/web/cure/ways");

        if (cards) {
          setCdata(cards.data.cure_ways_list);
        }
      } catch (err) {}
    };
    carddata();
  }, []);

  const ArticalData = async () => {
    await axios
      .get(
        "https://public-api.wordpress.com/rest/v1.1/sites/rxoneblog.wordpress.com/posts"
      )
      .then((res) => setArticalData(res.data.posts));
    return isArticalData;
  };

  const handleButtonClick = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.rxone&hl=en-IN",
      "_blank"
    );
  };

  const handleAppleClick = () => {
    window.open(
      "https://apps.apple.com/in/app/rxone-care-to-cure/id6479252685",
      "_blank"
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      const windowScrollTop = window.scrollY;
      const $videoWrap = document.querySelector(".video-wrap");
      const $video = document.querySelector(".video");
      const videoHeight = $video.offsetHeight;
      const videoBottom = videoHeight + $videoWrap.offsetTop;

      if (windowScrollTop > videoBottom) {
        $videoWrap.style.height = `${videoHeight}px`;
        $video.classList.add("stuck");
        setIsStuck(true);
      } else {
        $videoWrap.style.height = "auto";
        $video.classList.remove("stuck");
        setIsStuck(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const curepath = (item) => {
    const specialty = item.title; // Store specialty in a variable
    navigate(`/cureways/${item.code}`, {
      state: { specialty },
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsStuck(true);
    }, 5000); // Set the duration to 5 seconds (5000 milliseconds)

    // Clear the timer when component unmounts
    return () => clearTimeout(timer);
  }, []);

  const handleID = () => {
    window.open(
      "https://booking.setmore.com/scheduleappointment/e0da9b0f-e5cc-4f40-b54a-c65075466234"
    );
  };

  const handleRemoveVideo = () => {
    const videoWrapper = document.getElementById("video-wrapper");
    if (videoWrapper) {
      videoWrapper.remove();
      // setIsStuck(false);
    }
  };

  const handleregis = () => {
    navigate("/treatment-advice");
  };

  return (
    <div>
      {/* ******************** artical iframe *******************************  */}

      {/* ******************** article iframe *******************************  */}

      {/* <!-- Banner Section --> */}

      {blogpost ? (
        <BlogPost
          close={() => setBlogpost(false)}
          allBlog={isArticalData}
          setblog={setBlogData}
          blog={blogData}
        />
      ) : (
        <>
          <div>
            <div id="video-wrapper" className="video-wrap stuck">
              <div className="video">
                <iframe
                  title="RxOne Demo Video"
                  width="240"
                  height="135"
                  style={{
                    borderRadius: "10px",
                    boxShadow: "0 6px 14px rgba(5, 8, 7, 3.1)",
                  }}
                  src="https://www.youtube.com/embed/J53wLhoAn6I?si=XqXKU3x--GbxjPLT&autoplay=1&loop"
                  frameBorder="0"
                  gesture="media"
                  allowFullScreen
                ></iframe>
                {/* Cross button */}
                <button
                  className="cross-button"
                  onClick={handleRemoveVideo}
                  style={{
                    position: "absolute",
                    top: "-18px",
                    right: "-7px",
                    zIndex: 999,
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    // color: "black",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                  onMouseEnter={(e) => (e.target.style.color = "#005c74")}
                >
                  <CancelOutlinedIcon
                    style={{ color: "#fff", cursor: "pointer" }}
                  />
                </button>
              </div>
            </div>

            {/* {!isStuck && (
        <button onClick={() => setIsStuck(true)}>Show Video</button>
      )} */}
          </div>

          <div
            onClick={navigateTop}
            id="navigate_top"
            className={`home__up__navigation d-flex justify-content-center ${
              !nav && "d-none"
            }`}
          >
            <div className="home_up_arrow d-flex justify-content-center align-items-center ">
              <a herf="#">
                <ArrowUpwardOutlinedIcon
                  sx={{ fontSize: 30, color: "white" }}
                />
              </a>
            </div>
          </div>
          {/* <section style={{ gap: "0px" }} className="ms-home-banner ">
            <div data-aos="fade-up" className="container">
              <div className=" home__banner row align-items-center">
                <div className="col-xl-9 col-xxl-5 mt-5">
                  <h1 className="fw-bold home__title mb-4 mt-5">
                    Care to Cure{" "}
                    <h2 style={{ color: "#07ab9d", fontWeight: "700" }}>
                      One App for All Care Needs
                    </h2>
                  </h1> */}
          {/* <Link to="/booking" className=" home__btn ms-primary-btn" id="swap">Book Appointment<i className="fas fa-chevron-right ms-2"></i></Link> */}
          {/* <div>
                    <button
                      type="button"
                      className="getapp-container"
                      onClick={handleButtonClick}
                    >
                      <img
                        src={require("../Images/googleplaystore-icon.png")}
                        alt="Get the app on Google Play"
                      />
                    </button>
                    <button
                      type="button"
                      className="getapp-contain"
                      onClick={handleAppleClick}
                      style={{ margin: "8px" }}
                    >
                      <img
                        src={require("../Images/Appleicon.png")}
                        alt="Get the app on Apple Play"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="home__swiper__box">
              <Carousel
                controls={false}
                interval="2000"
                indicators={false}
                className="home__swiper"
              >
                <Carousel.Item className="home__swiper__container">
                  <img src={Image1} alt="First slide" />
                </Carousel.Item>
                <Carousel.Item className="home__swiper__container">
                  <img src={bannerImg1} alt="First slide" />
                </Carousel.Item>
                <Carousel.Item className="home__swiper__container">
                  <img
                    style={{ height: "80%" }}
                    src={bannerImg2}
                    alt="Second slide"
                  />
                </Carousel.Item>
                <Carousel.Item className="home__swiper__container">
                  <img src={bannerImg3} alt="Third slide" />
                </Carousel.Item>
              </Carousel>
            </div>
          </section> */}

          <section style={{ gap: "0px" }} className="ms-providers-banner">
            <div className="container provider__containers">
              <div data-aos="fade-up" className="row align-items-center">
                <div className=" provider__container col-xl-9 col-xxl-7 mt-5">
                  <h1 className="fw-bold mb-3 mt-5">{headings[activeIndex]}</h1>
                  <div>
                    <button
                      type="button"
                      className="getapp-container"
                      onClick={handleButtonClick}
                    >
                      <img
                        src={require("../Images/googleplaystore-icon.png")}
                        alt="Get the app on Google Play"
                      />
                    </button>
                    <button
                      type="button"
                      className="getapp-contain"
                      onClick={handleAppleClick}
                      style={{ margin: "8px" }}
                    >
                      <img
                        src={require("../Images/Appleicon.png")}
                        alt="Get the app on Apple Play"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="provider__swiper__box">
              <Carousel
                activeIndex={activeIndex}
                onSelect={handleSelects}
                controls={false}
                interval="1000"
                indicators={false}
                className="provider__swiper"
              >
                {bannerImages.map((bannerImg, index) => (
                  <Carousel.Item
                    key={index}
                    className="provider__swiper__container"
                  >
                    <img src={bannerImg} alt={`Slide ${index + 1}`} />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </section>

          {/* ---find cureways---- */}
          <section
            id="care-paths-section"
            data-aos-duration="3000"
            className="ms-cured-section"
          >
            <div
              data-aos="fade-up"
              className="container"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            >
              <h2
                className="ms-font-weight-600 ms-primary-color"
                style={{ textAlign: "center" }}
              >
                Discover Care Paths
              </h2>
              <span
                style={{
                  display: "block",
                  width: "100vw", // Use 100vw for full viewport width
                  backgroundColor: "rgb(0 92 116)",
                  color: "#ffffff",
                  padding: "6px",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "21px",
                  marginTop: "53px",
                  position: "relative", // Add position relative
                  left: "50%", // Move the element 50% to the left
                  right: "50%", // Move the element 50% to the right
                  marginLeft: "-50vw", // Compensate the left movement
                  marginRight: "-50vw", // Compensate the right movement
                }}
              >
                Worried about your treatment? Get a free consultation from our
                Care Advisor today!{" "}
                <button className="handlrs" onClick={handleregis}>
                  <span style={{ fontSize: "17px" }}>BOOK NOW</span>
                </button>
              </span>
              <div className="row align-items-start mb-4 g-5 flex-column flex-lg-row margin__first">
                <div data-aos="fade-left" className="col-lg-6 d-flex flex-row">
                  <div className="cred">
                    <img src={aboy} alt="aboy Image" className="dino" />

                    <div className="d-flex mb-3">
                      <h3
                        className="ms-font-weight-600 mb-4 ms-primary-color"
                        style={{
                          fontSize: "40px",
                          marginLeft: "110px",
                          textAlign: "left",
                        }}
                      >
                        Couldn’t decide about the right treatment for you?
                      </h3>
                    </div>
                  </div>
                </div>

                <div data-aos="fade-right" className="col-lg-6">
                  <div className="fred">
                    <img src={doctorwrite} alt="aboy Image" className="fino" />

                    <h3
                      className="ms-font-weight-600 mb-4 ms-primary-color"
                      style={{ fontSize: "40px" }}
                    >
                      We are here to Help !
                    </h3>
                    <div className="d-flex mb-3">
                      <h6
                        className="ms-2 mb-0 fw-normal"
                        style={{ fontSize: "24px" }}
                      >
                        Consult with our in-house specialist today to find which
                        treatment suits your condition.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isMobile && (
              <div
                className="image-contain align-items-start justify-content-end"
                style={{ marginLeft: "-44px", marginRight: "29px" }}
              >
                <img
                  src={statsappoint}
                  alt="statsappoint"
                  className="stats-image"
                />
              </div>
            )}
            {!isMobile && (
              <img
                src={consultpic}
                alt="consult"
                style={{ width: "100%", marginTop: "10px" }}
              />
            )}
          </section>

          {/* cureqays cards */}
          <section data-aos-duration="3000" className="ms-cureways-section">
            <div data-aos="fade-up" className="container">
              <div className="row mb-5">
                <div className="col-md-12 text-center">
                  <h2 className="ms-font-weight-600 ms-primary-color">
                    Cure Ways
                  </h2>
                </div>
              </div>
              <div className="grid row g-4">
                {cdata.map((item, index) => (
                  <div
                    key={index}
                    data-aos="flip-left"
                    className="flip__card col-md-4"
                    onClick={() => {
                      curepath(item);
                    }}
                  >
                    <div className="flip__card__inner ms-icon-box">
                      <div className="flip__card__front">
                        <img
                          src={item.icon_url}
                          className="img-fluid"
                          alt="Image"
                        />
                        <h4 style={{ fontWeight: "550" }} className="mt-4 mb-0">
                          {item.title}
                        </h4>
                      </div>
                      <div className="flip__card__back">
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* <!-- Stand Apart Section --> */}
          <section data-aos-duration="3000" className="ms-apart-section">
            <div data-aos="fade-up" className="container">
              <div className="row mb-5">
                <div className="col-md-12 text-center">
                  <h2 className="ms-font-weight-600 ms-primary-color">
                    What makes us stand apart !
                  </h2>
                </div>
              </div>
              <div className="grid row  g-4 ">
                <div data-aos="flip-left" className=" flip__card col-md-4 ">
                  <div className="flip__card__inner ms-icon-box">
                    <div className=" flip__card__front">
                      <img
                        src={Appointment}
                        className="img-fluid"
                        alt="Image"
                      />
                      <h5 style={{ fontWeight: "550" }} className=" mt-4 mb-0">
                        All your medical needs fulfilled by your trusted Care
                        provider !
                      </h5>
                    </div>
                    <div className="flip__card__back">
                      <p>
                        Book Doctor’s appointment, order Lab Tests or Order
                        Medicines directly from your trusted
                        Hospital/Clinic/Pharmacy. We act as just a bridge
                        between you and your trusted provider.
                      </p>
                    </div>
                  </div>
                </div>
                <div data-aos="flip-down" className=" flip__card col-md-4 ">
                  <div className="flip__card__inner ms-icon-box">
                    <div className=" flip__card__front">
                      <img src={Medicine} className="img-fluid" alt="Image" />
                      <h5 style={{ fontWeight: "550" }} className=" mt-4 mb-0">
                        Track your Health Parameters, Better results through
                        Clinical Insights !
                      </h5>
                    </div>
                    <div className="flip__card__back">
                      <p>
                        Your critical Bio-Markers not only helps you to know the
                        state of your health, but can improve the quality of
                        diagnosis by doctor.
                      </p>
                    </div>
                  </div>
                </div>
                <div data-aos="flip-left" className=" flip__card col-md-4 ">
                  <div className="flip__card__inner ms-icon-box">
                    <div className=" flip__card__front">
                      <img src={Lab} className="img-fluid" alt="Image" />
                      <h5 style={{ fontWeight: "550" }} className=" mt-4 mb-0">
                        Your Health records with you, Always !
                      </h5>
                    </div>
                    <div className="flip__card__back">
                      <p>
                        No need to manage health records in different apps.
                        Manage your health records with ease and control access
                        on your data
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- Content Section --> */}
          <section className="ms-content-section">
            <div className="container">
              <div className="row align-items-center mb-5 g-5 flex-column-reverse flex-lg-row margin__first">
                <div data-aos="fade-right" className="col-lg-6">
                  <div className="content__logo">
                    <img src={homeIcon1} alt="content-logo" />
                  </div>
                  <h3 className="ms-font-weight-600 mb-4 ms-primary-color">
                    Connect directly to your trusted Doctor and Care Provider
                  </h3>
                  <div className="d-flex mb-3">
                    <i className="fas fa-check-circle ms-check-icon"></i>
                    <h6 className="ms-2 mb-0 fw-normal">
                      Consult from top care specialists of your choice.
                    </h6>
                  </div>
                  <div className="d-flex mb-3">
                    <i className="fas fa-check-circle ms-check-icon"></i>
                    <h6 className="ms-2 mb-0 fw-normal">
                      One interface to book your Lab Tests and Order Medicines
                      from your trusted Clinic/Hospital/Pharmacy.
                    </h6>
                  </div>
                  <div className="d-flex mb-3">
                    <i className="fas fa-check-circle ms-check-icon"></i>
                    <h6 className="ms-2 mb-0 fw-normal">
                      Help doctor get better diagnosis for you by sharing your
                      Health Monitor and Health records remotely.
                    </h6>
                  </div>
                </div>
                <div data-aos="fade-left" className="col-lg-6">
                  <img
                    src={connect}
                    className="img-fluid ms-border-rounded"
                    alt="Image"
                  />
                </div>
              </div>
              <div className="row align-items-center g-5 margin__second">
                <div
                  style={{ width: "600px" }}
                  data-aos="fade-right"
                  className="col-lg-6"
                >
                  <img
                    src={Image2}
                    className="img-fluid ms-border-rounded fade__img"
                    alt="Image"
                  />
                </div>
                <div data-aos="fade-left" className="col-lg-6 ">
                  <div className="content__logo">
                    <img src={homeIcon2} alt="content-logo" />
                  </div>
                  <h3 className="ms-font-weight-600 mb-4 ms-primary-color">
                    Discover Better Results through Health Insights
                  </h3>
                  <div className="d-flex mb-3">
                    <i className="fas fa-check-circle ms-check-icon"></i>
                    <h6 className="ms-2 mb-0 fw-normal">
                      Track your critical Bio-Markers like Body Temperature,
                      Blood Pressure, SPO2, Blood Sugar and others
                    </h6>
                  </div>
                  <div className="d-flex mb-3">
                    <i className="fas fa-check-circle ms-check-icon"></i>
                    <h6 className="ms-2 mb-0 fw-normal">
                      Better prognosis and diagnosis through Bio-Markers trend
                      analysis by doctors
                    </h6>
                  </div>
                  <div className="d-flex mb-3">
                    <i className="fas fa-check-circle ms-check-icon"></i>
                    <h6 className="ms-2 mb-0 fw-normal">
                      Monitor your own progress towards better health through
                      progress charts
                    </h6>
                  </div>
                </div>
              </div>
              <div className="row align-items-center mb-5 g-5 flex-column-reverse flex-lg-row margin__first">
                <div data-aos="fade-right" className="col-lg-6">
                  <div className="content__logo">
                    <img src={homeIcon3} alt="content-logo" />
                  </div>
                  <h3 className="ms-font-weight-600 mb-4 ms-primary-color">
                    Your data, is in safe hands
                  </h3>
                  <div className="d-flex mb-3">
                    <i className="fas fa-check-circle ms-check-icon"></i>
                    <h6 className="ms-2 mb-0 fw-normal">
                      Integration with Ayushman Bharat Digital Health Mission
                      (Digital Health ID)
                    </h6>
                  </div>
                  <div className="d-flex mb-3">
                    <i className="fas fa-check-circle ms-check-icon"></i>
                    <h6 className="ms-2 mb-0 fw-normal">
                      Data is shared with Care Provider or Doctor only after
                      your Approval
                    </h6>
                  </div>
                  <div className="d-flex mb-3">
                    <i className="fas fa-check-circle ms-check-icon"></i>
                    <h6 className="ms-2 mb-0 fw-normal">
                      Protection from unauthorized access
                    </h6>
                  </div>
                  <div className="d-flex mb-3">
                    <i className="fas fa-check-circle ms-check-icon"></i>
                    <h6 className="ms-2 mb-0 fw-normal">
                      256-bit encryption of your records
                    </h6>
                  </div>
                </div>
                <div data-aos="fade-left" className="col-lg-6">
                  <img
                    src={Image3}
                    className="img-fluid ms-border-rounded"
                    alt="Image"
                  />
                </div>
              </div>
            </div>
          </section>

          {/* <!-- Top Articles Section --> */}

          <section className="ms-article-section">
            <div data-aos="fade-up" className="container">
              <div className="row align-items-center">
                <div className="col-lg-9">
                  <h3 className="ms-font-weight-600 ms-primary-color">Blog</h3>
                  <p className="fw-normal ms-third-color mb-3 mb-lg-0">
                    Articles & Videos by the health experts, that keep you
                    informed about good health practices and latest development
                    in health-care.
                  </p>
                </div>
                <div className="col-lg-3 text-start text-lg-end">
                  <Link to="/blogs" className="ms-secondary-btn">
                    View All <i className="fas fa-chevron-right ms-2"></i>
                  </Link>
                </div>
              </div>
              <div className="row mt-4">
                {isArticalData.length === 0 ? (
                  <div
                    style={{ width: "100%" }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  isArticalData.slice(0, 2).map((res, item) => (
                    <div
                      key={item}
                      data-aos="fade-up"
                      className="col-md-6 col-lg-4"
                    >
                      <article className="ms-article-box">
                        <div className="ms-featured-img position-relative overflow-hidden">
                          {res.post_thumbnail == null ? (
                            <img
                              src={testImage}
                              className="img-fluid"
                              alt="Article Image"
                            />
                          ) : (
                            <img
                              src={res.post_thumbnail.URL}
                              className="img-fluid"
                              alt="Article Image"
                            />
                          )}
                          <div className="ms-category-meta">
                            <a href="#" onClick={() => navigation(res)}>
                              {
                                res.categories[Object.keys(res.categories)[0]]
                                  .name
                              }
                            </a>
                          </div>
                        </div>
                        <div className="ms-article-content bg-white">
                          <a className="ms-primary-color" href="#.">
                            <h5 className="ms-font-weight-600">{res.title}</h5>
                          </a>
                          <div className="ms-author-meta my-3">
                            <img src={logo} className="img-fluid" alt="Image" />
                            <p className="mb-0 d-inline ms-third-color">
                              {res.author.first_name}
                            </p>
                          </div>
                          <hr className="ms-third-color" />
                          <div className="ms-description">
                            <p>
                              {truncate(
                                res.excerpt
                                  .replace("<p>", " ")
                                  .replace("</p>", ""),
                                180
                              )}
                            </p>
                            <p onClick={() => navigation(res)}>
                              <a className="ms-primary-color position-relative">
                                Read more
                              </a>
                            </p>
                          </div>
                        </div>
                      </article>
                    </div>
                  ))
                )}
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
}

export default Home;
